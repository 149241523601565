import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledProofAndBadgesEnterpriseComponent = styled.div`
  max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
  margin: auto;

  .side-by-side-text-and-asset {
    .side-by-side-content {
      .full-paragraph-component-wrapper {
        height: 100%;
        padding: 0 32px 0 0;
      }

      .image-wrapper.center {
        height: 100%;
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .side-by-side-text-and-asset {
      .side-by-side-content {
        .full-paragraph-component-wrapper {
          height: 100%;
          padding: 0;
        }

        .image-wrapper.center {
          margin-top: 32px;
        }
      }
    }
  }
`;
