import styled from "@emotion/styled";
export const StyledProofsSectionComponent = styled.div`
  height: 100%;
  .proofs-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .proof {
      background: linear-gradient(rgba(66, 66, 66, 1) 0%, rgba(0, 0, 0, 1) 100%);
      ${({ backgroundImage })=>backgroundImage ? `background-image: url(${backgroundImage})` : ""}
      flex: 1;
      padding: 40px;
      border: 1px solid #545454;
      border-radius: 12px;
    }
  }
`;
