import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledBadgesSectionComponent = styled.div`
  height: 100%;
  width: 100%;

  .badges-sections-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    background: linear-gradient(rgba(66, 66, 66, 1) 0%, rgba(0, 0, 0, 1) 100%);
    border: 1px solid #545454;
    border-radius: 12px;
    padding: 40px;

    .badges-section {
      .section-header {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #545454;

        .icon {
          width: 48px;
          margin-right: 16px;
        }

        .section-info {
          display: flex;
          flex-direction: column;
          align-self: center;
          gap: 8px;

          .title {
            font-size: 18px;
          }

          .description {
            font-size: 16px;
          }
        }
      }

      .badges-container {
        display: flex;
        justify-content: center;
        gap: 32px;

        .badge {
          width: 100px;
          width: min(calc((100vw / ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}) * 100), 100px);
        }
      }
    }
  }
`;
